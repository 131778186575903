import React from 'react';
import get from 'lodash.get';
import { Container } from '@material-ui/core';
import { graphql } from 'gatsby';
import { SITE_METADATA } from 'src/constants';
import { Layout } from 'src/components';
import {
  PreviewSec,
  NavigationSec,
  ItemSec,
  FormSec,
} from 'src/components/mediaCenterSections/pressKit';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import * as imgPressKitBanner from 'src/images/press-kit-banner.png';
import styles from 'src/components/PressKit/PressKit.module.scss';
import * as imgItemSec1 from 'src/images/press_kit/item-sec-1.png';
import * as imgItemSec2 from 'src/images/press_kit/item-sec-2.png';
import * as imgItemSec3 from 'src/images/press_kit/item-sec-3.png';

export const query = graphql`
  {
    contentfulPagePressKit {
      pageTitle
      pageSubtitle
      pageDescription
      pageBackground {
        file {
          url
        }
      }
      firstSectionTitle
      firstSectionDescription
      firstSectionImage {
        file {
          url
        }
      }
      firstSectionFile {
        file {
          url
        }
      }
      secondSectionTitle
      secondSectionDescription
      secondSectionImage {
        file {
          url
        }
      }
      secondSectionFile {
        file {
          url
        }
      }
      thirdSectionTitle
      thirdSectionDescription
      thirdSectionImage {
        file {
          url
        }
      }
      thirdSectionFile {
        file {
          url
        }
      }
      fourthSectionTitle
      fourthSectionDescription
    }
  }
`;

const PressKit = ({ location, data }: { location: Object; data: Object }) => {
  const navBlocks = [
    {
      id: 'firstSec',
      title: 'アプリ画面',
    },
    {
      id: 'secondSec',
      title: get(data, 'contentfulPagePressKit.secondSectionTitle'),
    },
    {
      id: 'thirdSec',
      title: get(data, 'contentfulPagePressKit.thirdSectionTitle'),
    },
    {
      id: 'fourthSec',
      title: 'PR お問い合わせ',
    },
  ];

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.pressKit.title,
        description: SITE_METADATA.pressKit.description,
      }}
    >
      <PreviewSec
        title={get(data, 'contentfulPagePressKit.pageTitle')}
        image={imgPressKitBanner}
      />
      <Breadcrumb
        customLevels={[
          {
            path: '/',
            title: 'トップ',
          },
          {
            path: '/press_kit/',
            title: 'プレスキット',
          },
        ]}
        className={styles.breadcrumb}
      />

      <NavigationSec
        blocks={navBlocks}
        location={location}
        full={false}
        tabStyle={undefined}
      />

      <section className={styles.sectionPageDescription}>
        <Container maxWidth="lg">
          <h1 className={styles.textDescription}>
            Paidyロゴやプロダクト写真など、Paidyに関するアセットをダウンロードしてご利用いただくことが可能です。
            <br />
            <br />
            ※ダウンロード・ご利用は企業およびブランドのガイドラインに準拠して行われる必要があります。
          </h1>
        </Container>
      </section>

      <ItemSec
        title="アプリ画面"
        description={get(
          data,
          'contentfulPagePressKit.firstSectionDescription'
        )}
        image={get(data, 'contentfulPagePressKit.firstSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.firstSectionFile.file.url')}
        id={navBlocks[0].id}
        imgClassName={styles.imgItemSec1}
      />

      <ItemSec
        title="ペイディロゴ"
        description={`ペイディロゴをダウンロード<br />※ダウンロードは企業およびブランドのガイドラインに準拠して行ってください。`}
        image={get(data, 'contentfulPagePressKit.secondSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.secondSectionFile.file.url')}
        id={navBlocks[1].id}
        imgClassName={styles.imgItemSec2}
      />

      <ItemSec
        title={get(data, 'contentfulPagePressKit.thirdSectionTitle')}
        description={`ロゴやカラー、フォントなどペイディブランドに関するルールを記載しています。`}
        image={get(data, 'contentfulPagePressKit.thirdSectionImage.file.url')}
        file={get(data, 'contentfulPagePressKit.thirdSectionFile.file.url')}
        id={navBlocks[2].id}
        imgClassName={styles.imgItemSec3}
      />

      <FormSec
        title={get(data, 'contentfulPagePressKit.fourthSectionTitle')}
        description="ペイディへのご取材やブランドアセット使用に関してご質問・ご依頼等がございましたら、こちらからお気軽にお問い合わせください。"
        id={navBlocks[3].id}
        button="送信"
      />
    </Layout>
  );
};

export default PressKit;
